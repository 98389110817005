/** @jsx jsx */
import "normalize.css";
import Layout from "../components/layout";
import SEO from "../components/seo";
import React from 'react';
import AudioPlayer from "../components/audioplayer";
import MarkupMedia from "../components/markupmedia";
import {css, jsx} from "@emotion/core";
import Sprechblase from "../components/sprechblase";
import BausteinListe from "../components/bausteinliste";
import {StaticQuery} from "gatsby";
import {DgsPlayButton} from "../components/dgsplayer";

const mainStyle = css`
    width: 75%;
    @media screen and (max-width: 1024px) {
        width: 100%;
        padding-top: 1rem;
    }
    box-sizing: border-box;
    margin: 0 1rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const illustrationStyle = css`
    min-height: 0px;
    height: 100%;
    flex: 1;
    div {
        height: 100%;
        width: 100%;
        margin: 0 auto;
    }
    picture {
        display: unset;
        height: 100%;
    }
    img {
        max-width: 100%;
        max-height: 100%;
        height:  min(calc(100vh - 320px - 125px - 44px), 80vw);
        width: min(calc(102vh - 320px - 125px - 44px), 70vw);
        margin: 0 auto;
    }
    @media screen and (max-width: 1024px) {
        img {
            height: auto;
        }
    }
`;

const headingStyle = css`
    text-align: center;
    color: #4EB8CC;
    padding-top: 1rem;
    z-index: 1;
    margin: 0;
`;

const textStyle = css`
    padding: 1rem 2rem;
    z-index: 1;
`;

const audioStyle = css`
    text-align: right;
    padding: 0 2rem 1rem 2rem;
    z-index: 1;
`;

const sidebarStyle = css`
    width: 25%;
    @media screen and (max-width: 1024px) {
        width: 75%;
        margin: auto;
    }
    font-size: 1.25rem;
`;

const startContainerStyle = css`
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 1024px) {
        flex-direction: row;
        max-height: calc(-169px - 2rem + 100vh);
        padding: 0.5rem;
    }
    width: 100%;
`;

const IndexPage = () => {

    return <Layout longPage={false}>
        <SEO title={"Hallo"}/>
        <div css={startContainerStyle}>
            <StaticQuery query={
                graphql`
                    query {
                        startinfoFixedMarkup {
                            audiodeskription
                            begruessungDGSVimeoID
                            begruessung
                            begruessungTitel
                            illustration {
                              markup
                              wideImage
                              aspectRatio
                            }
                        }
                    }
                `
            } render={({startinfoFixedMarkup}) => (
                <div css={mainStyle}>
                    <Sprechblase stroke={"white"} strokeWidth={0} fill={"white"} top={true}>
                        <h1 css={headingStyle}>{startinfoFixedMarkup.begruessungTitel}</h1>
                        <div css={textStyle} dangerouslySetInnerHTML={{__html: startinfoFixedMarkup.begruessung}}/>
                            <div css={audioStyle}>
                                { startinfoFixedMarkup.audiodeskription && <AudioPlayer audioFile={startinfoFixedMarkup.audiodeskription}/> }
                                { startinfoFixedMarkup.begruessungDGSVimeoID && <DgsPlayButton vimeoVideoId={startinfoFixedMarkup.begruessungDGSVimeoID}/> }
                            </div>
                    </Sprechblase>
                    <div css={illustrationStyle}>
                        <MarkupMedia markup={startinfoFixedMarkup.illustration.markup} aspectRatio={startinfoFixedMarkup.illustration.aspectRatio}/>
                    </div>
                </div>
            )}/>
            <div css={sidebarStyle}>
                <BausteinListe/>
            </div>
        </div>
    </Layout>
};

export default IndexPage;
