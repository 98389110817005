/** @jsx jsx */
import React from "react";
import {jsx, css} from "@emotion/core";
import {GlobalStyles} from "../styles/globalstyles";
import {graphql, Link, StaticQuery} from "gatsby";

import bestslug from "../bestslug";
import AudioPlayer from "./audioplayer";
import {DgsPlayButton} from "./dgsplayer";

class BausteinButton extends React.Component {

    render() {
        const baustein = this.props.baustein;

        const buttonStyle = css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            border-width: 6px;
            border-style: solid;
            border-radius: 25px;
            background-color: white;
            border-color: ${baustein.color};
            cursor: pointer;
            transition: transform 0.5s ease-out;
            display: flex;
            box-sizing: border-box;
            a, :hover, :visited {
                margin: auto;
                text-decoration: none;
                color: black;
            }
        `;

        const buttonTextStyle = css`
            position: relative;
            margin: auto auto auto 0.5rem;
            
        `;

        const buttonContainerStyle = css`
            position: relative;
            height: 4.5rem;
            width: 100%;
            @media (max-height: 768px) and (min-width: 1024px) {
               height: 4rem;
            }
        `;

        const linkStyle = css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0; 
        `;

        const audioPlayerStyle = css`
            display: flex;
            margin: auto 0.5rem auto auto;
            z-index: 5;
        `;

        return <div css={buttonContainerStyle}>
            <div css={[buttonStyle, GlobalStyles.buttonPop]}>
                <div css={buttonTextStyle}>{baustein.name}</div>
                <Link css={linkStyle} to={"/baustein/"+bestslug(baustein.name)} aria-label={baustein.name} />
                    <div css={audioPlayerStyle}>
                        { baustein.nameAudio && <AudioPlayer audioFile={baustein.nameAudio}/> }
                        { baustein.buttonVimeoDgsVideoId && <DgsPlayButton vimeoVideoId={baustein.buttonVimeoDgsVideoId}/>}
                    </div>
            </div>
        </div>
    }

}

class BausteinListe extends React.Component {

    render() {

        const listStyle = css({
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row"
        });

        const buttonWrapperStyle = css`
            margin: auto;
            width: 100%;
            box-sizing: border-box;
            padding: 0 0 1.5vh 0;
            margin: 0;
            @media (max-height: 768px) and (min-width: 1024px) {
                padding: 0 0 1vh 0;
                font-size: 1.125rem;
            }
        `;

        return <StaticQuery query={graphql`
            query {
                allBausteineJsonFixedMarkup(filter: {name: {ne: null}}) {
                    edges {
                      node {
                        color
                        alternative_id
                        name
                        nameAudio
                        buttonVimeoDgsVideoId
                      }
                    }
                }
            }     
        `} render={(data) => (
            <div css={listStyle}>
                {
                    data.allBausteineJsonFixedMarkup.edges.map(edge => {
                        return <div css={buttonWrapperStyle} key={edge.node.alternative_id}>
                            <BausteinButton baustein={edge.node} />
                        </div>
                    })
                }
            </div>
        )} />
    }

}

export default BausteinListe;
